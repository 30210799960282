const hamburger = document.querySelector('#hamburger');
const mainNavigation = document.querySelector('#main-navigation');
const languageTrigger = document.getElementById('language-selector-trigger');

hamburger?.addEventListener('click', () => {
  const expanded = hamburger.getAttribute('aria-expanded') === 'true';
  if (expanded) {
    hamburger.setAttribute('aria-expanded', 'false');
  } else {
    hamburger.setAttribute('aria-expanded', 'true');
    languageTrigger?.setAttribute('aria-expanded', 'false');
    mainNavigation.querySelector('[role=menuitem]').focus();
  }
});
