const billing_address_input = document.querySelector(".checkout-form input[name='use_billing_address']");
if (billing_address_input) {
  billing_address_input.addEventListener('change', function () {
    const list_required_fields = [
      'billing_company',
      'billing_first_name',
      'billing_name',
      'billing_country',
      'billing_address',
      'billing_zip',
      'billing_city',
    ];
    let billing_address_container = document.querySelector('.billing_address.checkout-form');
    billing_address_container.classList.toggle('hidden');
    for (let i = 0; i < list_required_fields.length; i++) {
      const field = list_required_fields[i];
      let input = billing_address_container.querySelector(`input[name=${field}]`);
      if (!input) continue;
      input.toggleAttribute('required');
    }
  });
}
