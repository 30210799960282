'use strict';

// adds .long to items with longer title to enable hover effect

let markLongTitles = () => {
  let titles = document.querySelectorAll('.watchlist__item--title');

  titles.forEach(title => {
    if (title.scrollHeight > title.clientHeight) title.classList.add('long');
  });
};

// listens for Document loaded and htmx events

document.addEventListener('DOMContentLoaded', () => {
  markLongTitles();
});

document.addEventListener('htmx:afterSettle', () => {
  markLongTitles();
});

// Feedback for adding items to watchlist

document.addEventListener('click', function (event) {
  let clickedButton = event.target.closest('.button--add-watchlist');
  let clickedButtonCart = event.target.closest('.button--add-cart'); // Prüft, ob ein Button geklickt wurde

  let messageDiv = document.getElementById('watchlist_success_message');
  let messageDivCart = document.getElementById('cart_success_message');

  if (clickedButton) {
    messageDiv.classList.add('show'); // Klasse 'show' hinzufügen

    setTimeout(() => {
      messageDiv.classList.remove('show'); // Klasse nach 2 Sekunden entfernen
    }, 2000);
  }

  if (clickedButtonCart) {
    messageDivCart.classList.add('show'); // Klasse 'show' hinzufügen

    setTimeout(() => {
      messageDivCart.classList.remove('show'); // Klasse nach 2 Sekunden entfernen
    }, 2000);
  }
});
