'use strict';

const activateHeader = () => {
  let header = document.querySelector('header');
  let viewportHeight = document.querySelector('html').clientHeight;
  const ScrollPosition = () => {
    return window.scrollY;
  };
  const DetectScrollUp = oldScrollPosition => {
    return oldScrollPosition > ScrollPosition() ? true : false;
  };
  let oldScrollPosition = 0;

  document.addEventListener('scroll', () => {
    if (ScrollPosition() > viewportHeight) {
      header.classList.add('active');
      DetectScrollUp(oldScrollPosition) ? header.classList.add('up') : header.classList.remove('up');
      oldScrollPosition = ScrollPosition();
    }

    if (ScrollPosition() === 0) {
      header.classList.remove('active');
      header.classList.remove('up');
    }
  });
};

document.addEventListener('DOMContentLoaded', () => {
  activateHeader();
});

document.addEventListener('htmx:afterSettle', () => {
  activateHeader();
});
