const languageTrigger = document.getElementById('language-selector-trigger');
const languageList = document.getElementById('language-selector-list');

const hamburger = document.querySelector('#hamburger');

languageTrigger?.addEventListener('click', event => {
  if (event.currentTarget.getAttribute('aria-expanded') === 'true') {
    event.currentTarget.setAttribute('aria-expanded', 'false');
  } else {
    event.currentTarget.setAttribute('aria-expanded', 'true');
    hamburger?.setAttribute('aria-expanded', 'false');
    languageList.querySelector('[role=menuitem]').focus();
  }
});

// class LanguageSelector {
//   constructor(domNode) {
//     this.domNode = domNode;
//
//     this.menuitems = [];
//
//     this.initLanguageSelector(domNode);
//   }
//
//   initLanguageSelector(domNode) {
//     const languageItems = [];
//   }
// }
